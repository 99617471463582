<template>
  <v-card
    :loading="loading"
    ligth
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        md="11"
        class="pa-1"
      >
        <v-row
          justify="space-between"
          align="center"
        >
          <v-col>
            <div
              class="ml-3 text-h3 text-weight-light"
            >
              Status Meu Clinic
            </div>
          </v-col>
          <v-btn
            color="primary"
            text
            @click="update()"
          >
            Atualizar
            <v-divider
              class="mx-4"
              vertical
            />
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        v-if="name || loading"
        cols="12"
        md="11"
      >
        <v-alert
          outlined
          prominent
          :type="type"
          text
        >
          <h2 class="font-weight-strong align-center mt-3 mb-3">
            Cliente: {{ name }}<br>
            Status: {{ status }}

            <v-progress-circular
              v-if="statusLoader"
              indeterminate
              color="primary"
            />
            <br>
            <div v-if="status==='Offline'">
              Diagnóstico: {{ problems[problemsIndex] }}
              <br>
              Ação recomendada: <br> {{ recomendedAction[recomendedActionIndex] }}
            </div>
          </h2>
        </v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import jwt from 'jsonwebtoken'
  import axios from 'axios'
  export default {
    name: 'Monitor',

    data () {
      return {
        customer: {},
        loading: true,
        status: '',
        statusLoader: true,
        tokenInfo: {},
        recomendedActionIndex: 3,
        recomendedAction: [
          'Contate a equipe técnica do MeuClinic para atualização do túnel.',
          'Siga os passos do manual abaixo. Caso o problema persista contate a equipe técnica do MeuClinic para o reparo do arquivo de configuração.',
          'Siga os passos do manual abaixo. Caso o problema persista contate a equipe técnica do MeuClinic para a ativação do túnel.',
          'Siga os passos do manual abaixo. Caso o problema persista contate a equipe técnica do MeuClinic para obter a solução adequada.',
        ],
        problemsIndex: 0,
        problems: [
          '',
          'Túnel de integração desatualizado.',
          'Erro no Spitzer Tentacle.',
          'Túnel de integração offline.',
        ],
      }
    },
    computed: {
      ...mapGetters('customers', ['customerList', 'pingStatistics']),
      domain () {
        return this.tokenInfo.domain
      },
      name () {
        return this.tokenInfo.name
      },
      type () {
        if (this.status === 'Online') {
          return 'success'
        } else {
          return 'warning'
        }
      },
    },
    mounted () {
      this.decodeToken()
      setInterval(() => { this.fullTeste() }, 10 * 60 * 1000) // 10min
    },
    methods: {
      ...mapActions('customers', ['listCustomers', 'getCustomers']),
      decodeToken () {
        jwt.verify(this.$route.params.id, 'vf50T8t4JoyA47nADZWGy', (err, decoded) => {
          if (err) {
            console.error(err.message)
            this.tokenInfo = { name: '', domain: '' }
          }
          if (!decoded) {
            console.error('Falha de decodificação')
            this.tokenInfo = { name: '', domain: '' }
          } else {
            this.tokenInfo = decoded
          }
        })
        this.update()
      },
      update () {
        this.loading = true
        this.statusLoader = true

        if (this.domain.includes('localtunnel')) {
          this.status = 'Offline'
          this.statusLoader = false
          this.loading = false
          this.recomendedActionIndex = 0
          this.problemsIndex = 1
          return
        }

        const requestDomain = this.domain
        const instance = axios.create({
          baseURL: requestDomain, // 'https://' + requestDomain.split('://')[1],
          headers: {
            'Access-Control-Allow-Origin': 'http://localhost:3002/',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
            'Access-Control-Allow-Credentials': true,
          },
        })

        instance.get('/config/status')
          .then((res) => {
            if (res.status) {
              this.status = 'Online'
              this.problemsIndex = 0
            }
            this.statusLoader = false
            this.loading = false
          })
          .catch((err) => {
            this.status = 'Offline'
            const errString = err.toString()
            if (err.response) {
              if (err.response.data.error === 'Falha ao listar os parametros') {
                console.error(err.response.data.error)
                this.recomendedActionIndex = 1
                this.problemsIndex = 2
              }
            } else if (errString.includes('Network Error')) {
              this.manual = true
              this.recomendedActionIndex = 2
              this.problemsIndex = 3
            } else {
              this.manual = true
              this.recomendedActionIndex = 3
              this.problemsIndex = 3
            }
            this.statusLoader = false
            this.loading = false
          })
      },
    },
  }
</script>
